<template>
  <b-container >
    <b-row class="mx-md-1">
      <b-col md="3" class="profile-center">
        <b-card
          :title="`Orden de Compra ${orders.purchase_order.correlative}`"
          tag="article"
          style="max-width: 20rem; border-radius: 20px"
          class="mb-2"
        >
          <b-card-text>
            <b-row align-v="center">
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>FECHA:</strong></h6>
                <h5>{{ orders.created_at }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>ÁREA:</strong></h6>
                <h5>{{ orders.purchase_order.area.name }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>ESTADO:</strong></h6>
                <h5>{{ orders.purchase_order.status_description }}</h5>
              </b-form-group>
              <b-form-group class="col-md-12">
                <h6 class="custom-text"><strong>SOLICITANTE:</strong></h6>
                <h5>{{ orders.purchase_order.user_applicant.fullname }}</h5>
              </b-form-group>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="9">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Productos</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table hover :items="products" :fields="columns">
                  <template v-slot:cell(quantityRequest)="data">
                    <span v-if="!data.item.editable">{{
                      data.item.quantity
                    }}</span>
                    <b-form-input
                      v-else
                      v-model="data.item.quantity"
                      type="text"
                      placeholder="cantidad"
                    ></b-form-input>
                  </template>
                  <template v-slot:cell(verificado)="data">
                    <div v-if="!data.item.editable">
                      <b-button
                        v-b-tooltip.hover
                        title="Agregar cantidad"
                        variant=" iq-bg-warning mr-1 mb-1"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'Show',

  mounted () {
    this.$store.commit("SET_BREADCRUMB", this.date);
    core.index()
  },
  data () {
    return {
      id: this.$route.params.id,
      orders: '',
      products: '',
      columns: [
        {
          label: 'SKU',
          key: 'sku',
          class: 'text-center'
        },
        { label: 'Producto', key: 'name', class: 'text-center' },
        {
          label: 'Cantidad Recibida',
          key: 'quantityRequest',
          class: 'text-center'
        }
      ],
      date: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          html: 'Recepción de órdenes de compras',
          to: '/purchase_reception/list'
        },
        {
          text: 'Detalles recepción órdenes de Compra',
          active: true
        }
      ]
    }
  },
  async created () {
    this.$store.commit('run')
    const res = await this.$store.dispatch('reception/showReception', this.id)
    this.orders = res
    this.products = res.products
    this.$store.commit('stop')
  },
  methods: {}
}
</script>
<style>
.custom-text {
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
